<template>
    <div class="press-releases">
         <van-search class="search-notify" v-model="listQuery.keyword" placeholder="请输入新闻标题" shape="round" background="transparent"  @search="onSearch" input-align="center"/>
         <!-- <van-pull-refresh v-model="refreshing" @refresh="onRefresh"> -->
            <van-list
                v-model="loading"
                :finished="finished"
                finished-text="没有更多了"
                @load="onLoad"
                v-if="!isNoData"
            >
                <van-swipe-cell v-for="(item, index) in list" :key="index">
                    <van-cell >
                        <!-- <div class="content-item"><span>标题:</span><span class="title" @click="seeDetails(item)">{{item.title}}</span></div>
                        <div class="left-label">内容:</div>
                        <div class="content-item mavon-item">
                            <div class="pop"></div> 
                            <mavon-editor ref="echoContent" :subfield="true" v-model="item.context" :toolbars="toolbars"/>
                        </div>  -->
                        <div class="content-item">
                            <div class="time">上次发布时间：{{item.createTime}}</div>
                            <div class="content">
                                <div class="img">
                                    <!-- <img src="../../assets/images/news/img_news_finshed.png"/> -->
                                    <img :src="item.url" @click="seeDetails(item)"/>
                                </div>
                                <div class="bottom">
                                    <p class="title" @click="seeDetails(item)">{{item.title}}</p>
                                    <p class="news-content">{{item.context1}}</p>
                                </div>
                            </div>
                        </div>
                    </van-cell>
                    <!--  <template #right>
                        <van-button square type="danger" text="删除" @click="deleteNotify(item.id)"/>
                    </template> -->
                </van-swipe-cell>
            </van-list>
            <div class="no-data" v-else>
                <div>
                    <img src="../../assets/images/icon_nodata.png"/>
                </div>
                <div>
                    <span class="no-data-text">暂无数据</span>
                </div>
            </div>
        <!-- </van-pull-refresh> -->
        <router-link :to="{path: '/addPress'}" v-show="isAdmin">
            <div class="btn-add">+</div>
        </router-link>
    </div>
</template>

<script>
import { Form } from 'vant';
import {
    getUserType
} from '@/utils/cookie';
import {listPageNewsNotice, addNewsNotice, modifyNewsNotice, deleteNewsNotice} from '@/api/notificationIssuance';
export default {
    data() {
        return {
            isNoData: false, //是否吴书记
            isAdmin: true,//判断是否是管理员
            refreshing: false,//是否刷新
            loading: false,//是否等待
            finished: false,//是否结束
            list: [],
            copyList: [
                {title: '休闲农业蔬菜主题农业观光园规划设计',
                context1: '依据园区所在地的自然资源，紧紧围绕蔬菜主题，充分利用田园景观、当地民俗风情和乡土文化，在体现自然美的基础上，运用美学和园', 
                url: require('@/assets/images/news/park1.jpeg'), createTime: '2021-04-21 10:01:00', level:2,top: 1},
                {title: '农业农事节会活动之“玫瑰庄园 玫瑰文化节”',
                context1: '为充分展示我市休闲农业发展成果，提高我市休闲农业知名度和影响力，丰富市民精神和文化生活，推进都市生态农业发展，2021年4月21日上午，郑州休闲农业农事节会活动之“玫瑰庄园•玫瑰文化节”启动仪', 
                url: require('@/assets/images/news/park2.jpg'), createTime: '2021-04-21 16:09:01', level:0,top: 0},
                {title: '现代农业产业园区：聚力乡村绿化 美化人居环境',
                context1: '一年之计在于春，春季绿化正当时。连日来，贾汪现代农业产业园区抢抓春季绿化最佳时机，开展春季绿化栽植、补植和养护，提升绿化覆盖率，美化乡村人居环境。园区内，工作人员在农谷大道绿化带正忙着播撒花种、补植苗木，给耿张线两侧新栽种的枫杨树养护浇水。', 
                url: require('@/assets/images/news/park3.jpg'), createTime: '2021-04-21 21:59:00', level:0,top: 0},
                {title: '农业园区春耕农忙正当时',
                context1: '4月22日，正值春耕农忙期，在县农业园区内，农民们抢抓农时，忙着耕地、种菜，一派忙碌的景象。据了解，为确保早日完成首批蔬菜种植，该园区内的蔬菜种植企业还在联系更多村民前来务工。', 
                url: require('@/assets/images/news/park4.jpg'), createTime: '2021-04-22 11:16:00', level:0,top: 0},
                {title: '丰收快讯 | 宁夏打造宁夏旱作节水高效农业科技园',
                context1: '近日，宁夏旱作节水高效农业科技园区走进了大家的视野。据了解，该园区通过技术开发、集成示范、科技合作及技术培训等措施，从研制抗旱优新品种、高效节水技术、抗旱农艺技术及综合生产模式等方面为农业“补水”。', 
                url: require('@/assets/images/news/park5.jpeg'), createTime: '2021-04-22 16:01:00', level:0,top: 0},
            ],
            listQuery: {
                keyword: '',
                pageIndex: 1,
                pageSize: 200,
                type: 1,
                orderField: 'level', 
                isAuth: false,
                sort: 2
            },
            toolbars: {
                editable: false
            }
        }
    },
    mounted() {
        this.isAdmin = getUserType() == 1 ? true : false;
        this.listPageNewsNotice()
    },
    methods: {
        /** 刷新 */
        onRefresh() {

        },
        /** 加载 */
        onLoad() {
            this.loading = false;
        },
        /** 查询列表 */
        listPageNewsNotice() {
            // this.list = JSON.parse(JSON.stringify(this.copyList));
            this.list = [];
            listPageNewsNotice(this.listQuery).then(res => {
                if (res.code == 200) {
                    // this.list = res.data || [];
                    res.data.map(item => {
                        if (item.context.indexOf('+') != -1) {
                            let arr = item.context.split('+');
                            item.context1 = arr[0];
                            item.url = this.getParenthesesStr(arr[1]);
                            this.list.push(item);
                        } else {
                            item.context1 = item.context;
                            item.url = require('../../assets/images/news/img_news_finshed.png');
                            this.list.push(item);
                        }
                    });
                    this.isNoData = res.data.length > 0 ? false : true;
                }
            });
        },
        /** 删除 */
        deleteNotify(id) {
            deleteNewsNotice({id: id}).then(res => {
                if (res.code == 200) {
                    this.$notify({type: 'success', message: '删除成功'});
                    this.listPageNewsNotice();
                }
            });
        },
        /** 查看详情 */
        seeDetails(obj) {
            this.$router.push({name: 'addPress', params: obj})
        },
        /** 搜索 */
        onSearch() {
            this.listPageNewsNotice();
        },
        /** 获取小括号中的内容 */   
        getParenthesesStr(text) {
            let result = '';
            let regex = /\((.+?)\)/g;
            let options = text.match(regex);
            result = options[0].substring(1, options[0].length - 1)
            return result
        },
        /** 改造数据 */
        transformData(data) {
            let result = [];
            if (data.indexOf('@') != -1) {
                let arr = data.split('@');
                arr.forEach((item, index) => {
                    if (item.indexOf('+') != -1) {
                        let tempArr = item.split('+');
                        let obj = {
                            content: tempArr[0],
                            time: tempArr[1],
                            url: tempArr[2]
                        }
                        result.push(obj);
                    }
                })
            } else {
                if (data.indexOf('+') != -1) {
                    let tempArr = item.split('+');
                    let obj = {
                        content: tempArr[0],
                        time: tempArr[1],
                        url: tempArr[2]
                    }
                    result.push(obj);
                }
            }
            return result;
        }
    },
   /*  beforeRouteEnter(to, from,next) {
        next(vm => {
            if (from.name == 'addPress') {
                let obj = {
                    title: to.query.title || '',
                    content: to.query.content || ''
                }
                vm.list.push(obj);
            }
        });
    } */
}
</script>

<style lang="scss" scoped>
    .press-releases {
        position: absolute;
        top: 0;
        bottom: 50px;
        width: 347px;
        margin: 0 auto;
        padding: 14px;
        // max-height: 660px;
        overflow: auto;
        .btn-add {
            position: fixed;
            bottom: 70px;
            right: 10px;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            font-size: 26px;
            color: #fff;
            text-align: center;
            line-height: 40px;
            background-color: #bfd9f6;
        }
        .left-label {
            width: 40px;
            margin: 8px;
            margin-left: 10px;
            font-size: 14px;
            color: #646566;
            text-align: left;
        }
    }
</style>
<style lang="scss">
    .press-releases {
        width: 347px;
        margin: 0 auto;
        padding: 14px;
        max-height: 600px;
        overflow: auto;
        .van-cell {
            width: 347px;
            margin: 0 auto;
            padding: 0;
            // margin-bottom: 10px;;
            background-color: transparent;
            .content-item {
                .time {
                    margin-bottom: 10px;
                    font-size: 12px;
                    color: #A3A3A3;
                }
                .content {
                    border-radius: 4px;
                    background: #fff;
                    .img {
                        border-top-left-radius: 4px;
                        border-top-right-radius: 4px;
                        img {
                            width: 347px;
                            height: 124px;
                            border-top-left-radius: 4px;
                            border-top-right-radius: 4px;
                        }
                    }
                    .bottom {
                        padding: 14px;
                        .title {
                            width: 308px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            font-size: 16px;
                            font-weight: 500;
                            color: #5A5A5A;
                        }
                        .news-content {
                            font-size: 14px;
                            color: #949494;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                    }
                }
            }
            .mavon-item {
                position: relative;
                .pop {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    z-index: 9999;
                } 
            }
        }
        .van-cell:nth-of-type(1) {
            margin-top: 10px;
        }
        .van-swipe-cell__right {
            top: 34px;
        }
        .van-button--danger,
        .van-swipe-cell__right {
            height: 100%;
            background-color: #ED7171;
            border: 1px solid #ED7171;
        }
        .v-note-wrapper {
            min-height: auto;
        }
        .v-note-wrapper .v-note-op {
            display: none;
        }
        .search-notify .van-cell {
            width: 346px;
            height: 32px;
            margin: 0 auto;
        }
        .v-note-wrapper .v-note-panel .v-note-edit.divarea-wrapper {
            display: none;
        }
    }
</style>